import React, { PureComponent, useState, useCallback, useEffect } from "react";
import Helmet from "react-helmet"
import Page from "../components/project_d.js"
import IndexSPA from "../components/IndexSPA"
import { Children } from "react";
import window from 'global'

// export default Page

const Index = (props) => {
   return (
      <>
      <Helmet
          title="展覽主題 / HOME TO ALL 我們也可以這樣生活 / 台北社會住宅藝術計畫"
          meta={[
            {
              name: `description`,
              content: "在這個城市中我們要如何一起生活？不是由統治者來告訴我們，而是讓我們來一起主動追尋，一起想像，一起行動，這是只有在民主自由的沃土裡，才能跟著綻放的公共藝術。",
            },
            {
              property: `og:title`,
              content: "展覽主題 / HOME TO ALL 我們也可以這樣生活 / 台北社會住宅藝術計畫",
            },
            {
              property: `og:description`,
              content: "在這個城市中我們要如何一起生活？不是由統治者來告訴我們，而是讓我們來一起主動追尋，一起想像，一起行動，這是只有在民主自由的沃土裡，才能跟著綻放的公共藝術。",
            },
            {
              property: `og:image`,
              content: 'https://online.home-to-all.com' + require('../images/discourse_b-1.jpg')
            }
          ]}
         >
         </Helmet>
         <IndexSPA />
         {/* <Page /> */}
      </>
   )
 };
 
 // Index.propTypes = {}
 
 export default Index;